import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {
  AUTH_TOKEN,
  PERMISSIONS_KEY,
  REFRESH_TOKEN_KEY,
  TOKEN_EXPIRE_DATE,
} from '../../../shared/constants/AppConst';
import {useDispatch, useSelector} from 'react-redux';
import {
  setAuthenticated,
  setInitialUrl,
  setMyPermissions,
} from 'src/domain/app/redux/auth/auth-slice';
import {authService} from 'src/shared/services/auth';
import MainUtils from 'src/shared/utils/main';
import routesConfig from 'src/pages/routeConfig';
import {getInitialUrl} from 'src/domain/app/redux/auth/auth-selectors';

const AuthProvider = (props: {children: React.ReactNode}) => {
  const [cookies, setCookies] = useCookies([AUTH_TOKEN]);
  const dispatch = useDispatch();
  const pathName = useSelector(getInitialUrl);
  const [targetDate, setTargetDate] = useState<any>();
  const [countdown, setCountdown] = useState({
    days: 14,
    hours: 60,
    minutes: 60,
  });
  const [lastCountdown, setLastCountdown] = useState({
    days: 14,
    hours: 60,
    minutes: 60,
  });

  useEffect(() => {
    if (cookies[AUTH_TOKEN]) {
      dispatch(setAuthenticated(true));
      (async () => {
        try {
          await authService.getMyRoles().then((response) => {
            if (!MainUtils.isEmptyValue(response?.Data)) {
              let permissionsAll = response?.Data?.map((role) => {
                const permissionKeys = role?.permissions?.map((permission) => {
                  return permission.key;
                });
                return permissionKeys;
              }).reduce((acc: any, curr: any) => acc.concat(curr), []);
              dispatch(setMyPermissions(permissionsAll));
              localStorage.setItem(
                PERMISSIONS_KEY,
                JSON.stringify(permissionsAll),
              );

              let myPermissionsIsSidebar: any[] = [];
              routesConfig
                .filter(
                  (item: any) =>
                    permissionsAll.filter((permission: any) =>
                      item.permissionKey.includes(permission),
                    )?.length > 0,
                )
                .map((item: any) => {
                  if (item.children) {
                    const children = item.children.filter((child: any) =>
                      permissionsAll.includes(child.permissionKey),
                    );
                    if (children?.length > 0)
                      myPermissionsIsSidebar.push(...children);
                    return;
                  }
                  myPermissionsIsSidebar.push(item);
                  return;
                });

              if (
                myPermissionsIsSidebar &&
                myPermissionsIsSidebar?.length > 0
              ) {
                dispatch(
                  setInitialUrl(
                    myPermissionsIsSidebar.filter((item: any) => item.path)[0]
                      .path,
                  ),
                );
              }
            }
          });
        } catch (error) {
          console.error(error);
        }
      })();
    } else {
      dispatch(setAuthenticated(false));
      dispatch(setInitialUrl('/sign-in'));
    }
  }, [cookies[AUTH_TOKEN], routesConfig]);

  useEffect(() => {
    if (localStorage.getItem(TOKEN_EXPIRE_DATE) && cookies[AUTH_TOKEN]) {
      const interval = setInterval(updateCountdown, 1000);
      return () => clearInterval(interval);
    }
  }, [targetDate, cookies[AUTH_TOKEN]]);

  useEffect(() => {
    if (localStorage.getItem(TOKEN_EXPIRE_DATE) && cookies[AUTH_TOKEN]) {
      const storedExpirationDate = localStorage.getItem(TOKEN_EXPIRE_DATE);
      const expirationDate = storedExpirationDate
        ? new Date(storedExpirationDate)
        : new Date();
      setTargetDate(expirationDate?.getTime());
    }
  }, [cookies[AUTH_TOKEN]]);

  useEffect(() => {
    if (
      lastCountdown?.days !== countdown?.days ||
      lastCountdown?.hours !== countdown?.hours ||
      lastCountdown?.minutes !== countdown?.minutes
    )
      setCountdown(lastCountdown);
  }, [lastCountdown, countdown]);

  useEffect(() => {
    if (
      countdown?.days <= 0 &&
      countdown?.hours <= 0 &&
      countdown?.minutes <= 30
    ) {
      (async () => {
        try {
          await authService
            .refreshToken({
              expiredToken: cookies[AUTH_TOKEN],
              refreshToken: cookies[REFRESH_TOKEN_KEY],
            })
            .then((response) => {
              const data = response?.Data;
              if (!MainUtils.isEmptyValue(data)) {
                setCookies(AUTH_TOKEN, data?.token, {
                  path: '/',
                  sameSite: true,
                  expires: new Date(response?.Data?.expiredAt),
                });
                localStorage.setItem(
                  TOKEN_EXPIRE_DATE,
                  `${new Date(response.Data.expiredAt)}`,
                );

                const currentRefreshTokenDate = new Date(
                  response?.Data?.expiredAt,
                );
                const newRefreshTokenDate = new Date(currentRefreshTokenDate);
                newRefreshTokenDate.setFullYear(
                  currentRefreshTokenDate.getFullYear() + 1,
                );
                setCookies(
                  REFRESH_TOKEN_KEY,
                  `${response?.Data?.refreshToken}`,
                  {
                    path: '/',
                    sameSite: true,
                    expires: newRefreshTokenDate,
                  },
                );
              }
            });
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [countdown]);

  const updateCountdown = () => {
    const now = new Date().getTime();
    const distance = targetDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    setLastCountdown({
      days,
      hours,
      minutes,
    });
  };

  const Loader = () => (
    <div className='loader'>
      <div className='site-logo'>
        <img src='/assets/images/logo.png' alt='logo' />
      </div>
      <div className='loader-spin'>
        <span className='ant-spin-dot ant-spin-dot-spin'>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
    </div>
  );
  return <>{pathName !== '/' ? props.children : <Loader />}</>;
};

export default AuthProvider;
